var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('header-main', {
    attrs: {
      "alertVisible": _vm.alertVisible
    }
  }), _c('v-main', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }],
    staticClass: "main"
  }, [_c('alert-message', {
    attrs: {
      "alert": _vm.alert
    }
  }), _c('router-view'), _c('search-sheet')], 1), _c('v-fab-transition', [_c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.scrollTopBtn,
      expression: "scrollTopBtn"
    }],
    staticClass: "btn-scroll-top",
    attrs: {
      "app": "",
      "bottom": "",
      "color": "primary",
      "fab": "",
      "fixed": "",
      "out": "",
      "right": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo(_vm.scrollTopTarget, _vm.scrollTopOptions);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "secondary"
    }
  }, [_vm._v("mdi-chevron-double-up")])], 1)], 1), _c('image-modal'), _c('footer-main')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }