var render = function () {
  var _vm$alert, _vm$alert2, _vm$alert3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-alert', {
    staticClass: "alert-message mb-0",
    style: {
      top: "".concat(_vm.$vuetify.application.top, "px")
    },
    attrs: {
      "dismissible": "",
      "elevation": "4",
      "type": (_vm$alert = _vm.alert) === null || _vm$alert === void 0 ? void 0 : _vm$alert.type,
      "value": !!((_vm$alert2 = _vm.alert) !== null && _vm$alert2 !== void 0 && _vm$alert2.id),
      "width": "100%"
    }
  }, [_c('span', {
    staticClass: "message-text font-weight-bold"
  }, [_vm._v(" " + _vm._s(((_vm$alert3 = _vm.alert) === null || _vm$alert3 === void 0 ? void 0 : _vm$alert3.message) || ''))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }